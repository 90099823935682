import { toast } from "react-toastify";

import { http } from '../../http';
import * as actionTypes from "../actionTypes";
import { GetTopFitnessLeaderboardData, GetTopSocialUsers, GetTopFitnessUsers, GetTopWellnessUsers, GetTopUserTotalPoints, GetUserPointsRank, GetCompanyTotalTeamPoints, GetTotalTeamUsersPoints, GetTeamIcons, AddOrUpdateTeamDetails, GetCompanyTeamCount, GetCompanyTeamAndMyTeamFlags, GetUserLeaderboardPoints } from "../../constants/apiConstants";

const networkError = (error) => (dispatch) => dispatch({ type: actionTypes.NETWORK_ERROR, networkError: error });

const getTopFitnessLeaderboard = (id,pageNumber, limit) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopFitnessLeaderboardData}/${id}/${pageNumber}/${limit}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.FITNESS_LEADERBOARD_DATA, fitnessLeaderboard: res, pageNumber: pageNumber, distanceLeaderboard: res['distance'], stepsLeaderboard: res['steps'], caloriesLeaderboard: res['calories'] });
      }
    } catch (error) {
      dispatch(networkError(error.message));
    }
  }
};

const getTopSocialUsers = (id,pageNumber, limit) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopSocialUsers}/${id}/${pageNumber}/${limit}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.SOCIAL_USERS_DATA,pageNumber: pageNumber, socialUserData: res[0] });
      }
    } catch (error) {
      dispatch(networkError(error.message));
    }
  }
};

const getTopFitnessUsers = (id,pageNumber, limit) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopFitnessUsers}/${id}/${pageNumber}/${limit}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.FITNESS_USERS_DATA,pageNumber: pageNumber, fitnessUserData: res[0] });
      }
    } catch (error) {
      dispatch(networkError(error.message));
    }
  }
};

const getTopWellnessUsers = (id,pageNumber, limit) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopWellnessUsers}/${id}/${pageNumber}/${limit}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.WELLNESS_USERS_DATA,pageNumber: pageNumber, wellnessUserData: res[0] });
      }
    } catch (error) {
      dispatch(networkError(error.message));
    }
  }
};

const getTopUserTotalPoints = (id,pageNumber, limit) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopUserTotalPoints}/${id}/${pageNumber}/${limit}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.TOP_USER_TOTAL_POINTS,pageNumber: pageNumber, topUsersTotalPoints: res[0] });
      }
    } catch (error) {
      dispatch(networkError(error.message));
    }
  }
};

const getCompanyTotalTeamPoints = (id,pageNumber, limit) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyTotalTeamPoints}/${id}/${pageNumber}/${limit}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.COMPANY_TEAM_POINTS,pageNumber: pageNumber, companyTeamPoints: res });
      }
    } catch (error) {
      dispatch(networkError(error.message));
    }
  }
};

const getTotalTeamUsersPoints = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTotalTeamUsersPoints}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.TEAM_USERS_POINTS, teamUsersPoints: res });
      }
    } catch (error) {
      dispatch(networkError(error.message));
    }
  }
};

const fetchTeamIcons = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTeamIcons}`, {headers: { AUTHTOKEN: AuthToken }});
      if (res.message) {
        toast.error(res.message);
      }else{
        dispatch({type: actionTypes.TEAM_ICONS, teamIconsData: res});
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchCompanyTeamsCount = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyTeamCount}/${id}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        // toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_COMPANY_TEAM_COUNT, teamsCount: res});
        // dispatch(FetchEmployeeCount(id))
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchCompanyTeamAndMyTeamFlags = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyTeamAndMyTeamFlags}/${id}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        // toast.error(res.message);
      }else{
        dispatch({type: actionTypes.GET_COMPANY_TEAM_AND_MY_TEAM_FLAGS, wellnessCount: res});
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const fetchAddOrUpdateTeamsDetails = (obj) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(AddOrUpdateTeamDetails, obj, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        toast.success(res[0]);
        // window.location.reload();
        dispatch(getTotalTeamUsersPoints())
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};


const getCompanyRank = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUserPointsRank}/${id}`, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.USER_RANK, userRank: res });
      }
    } catch (error) {
      dispatch(networkError(error.message));
    }
  }
};

const fetchLeaderboardAPI = (id,pageNumber, limit) => (dispatch) => {
  dispatch(getTopFitnessLeaderboard(id, pageNumber, limit));
  dispatch(getTopSocialUsers(id, pageNumber, limit));
  dispatch(getTopFitnessUsers(id, pageNumber, limit));
  dispatch(getTopWellnessUsers(id, pageNumber, limit));
  dispatch(getTopUserTotalPoints(id, pageNumber, limit));
  dispatch(getCompanyTotalTeamPoints(id, pageNumber, limit));
  dispatch(getTotalTeamUsersPoints());
  dispatch(fetchTeamIcons());
  dispatch(fetchCompanyTeamsCount(id));
  dispatch(fetchCompanyTeamAndMyTeamFlags(id));
}

const setCompany = (id, name, companyStartDate, showBiometricStatus) => (dispatch) => {
  dispatch({type: actionTypes.SET_COMPANY, id, name, companyStartDate, showBiometricStatus});
};

const getUserLeaderboardPoints = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetUserLeaderboardPoints, { headers: { AUTHTOKEN: AuthToken } });
      if (res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.USER_TOTAL_POINTS, userTotalPoints: res });
      }
    } catch (error) {
      dispatch(networkError(error.message));
    }
  }
};

export { getTopFitnessLeaderboard, getTopSocialUsers, getTopFitnessUsers, getTopWellnessUsers, fetchLeaderboardAPI, getTopUserTotalPoints, setCompany, getCompanyRank, getCompanyTotalTeamPoints, getTotalTeamUsersPoints, fetchTeamIcons, fetchAddOrUpdateTeamsDetails, fetchCompanyTeamsCount, fetchCompanyTeamAndMyTeamFlags, getUserLeaderboardPoints };

